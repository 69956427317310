// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-168-showflat-js": () => import("../src/pages/work/168-showflat.js" /* webpackChunkName: "component---src-pages-work-168-showflat-js" */),
  "component---src-pages-work-advance-architecture-js": () => import("../src/pages/work/advance-architecture.js" /* webpackChunkName: "component---src-pages-work-advance-architecture-js" */),
  "component---src-pages-work-art-4-d-pavillion-js": () => import("../src/pages/work/art4d-pavillion.js" /* webpackChunkName: "component---src-pages-work-art-4-d-pavillion-js" */),
  "component---src-pages-work-beta-conference-js": () => import("../src/pages/work/beta-conference.js" /* webpackChunkName: "component---src-pages-work-beta-conference-js" */),
  "component---src-pages-work-flashcard-js": () => import("../src/pages/work/flashcard.js" /* webpackChunkName: "component---src-pages-work-flashcard-js" */),
  "component---src-pages-work-generative-cover-js": () => import("../src/pages/work/generative-cover.js" /* webpackChunkName: "component---src-pages-work-generative-cover-js" */),
  "component---src-pages-work-index-js": () => import("../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-kumamoto-artpolis-js": () => import("../src/pages/work/kumamoto-artpolis.js" /* webpackChunkName: "component---src-pages-work-kumamoto-artpolis-js" */),
  "component---src-pages-work-learn-anywhere-js": () => import("../src/pages/work/learn-anywhere.js" /* webpackChunkName: "component---src-pages-work-learn-anywhere-js" */),
  "component---src-pages-work-march-mellow-js": () => import("../src/pages/work/march-mellow.js" /* webpackChunkName: "component---src-pages-work-march-mellow-js" */),
  "component---src-pages-work-skill-score-js": () => import("../src/pages/work/skill-score.js" /* webpackChunkName: "component---src-pages-work-skill-score-js" */),
  "component---src-pages-work-skooldio-web-js": () => import("../src/pages/work/skooldio-web.js" /* webpackChunkName: "component---src-pages-work-skooldio-web-js" */),
  "component---src-pages-work-tcdc-khon-kaen-js": () => import("../src/pages/work/tcdc-khon-kaen.js" /* webpackChunkName: "component---src-pages-work-tcdc-khon-kaen-js" */),
  "component---src-pages-work-trat-residence-js": () => import("../src/pages/work/trat-residence.js" /* webpackChunkName: "component---src-pages-work-trat-residence-js" */)
}

